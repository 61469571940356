import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import "../css/main.css";
import { Link } from "react-router-dom";

function Main() {
  return (
    <div className="main-container">
      <Header />
      <div className="main1_box">
        <div className="main1_box_inner">
          <div className="main1_internal">
            <div className="main1_txt_box">
              <div className="main1_banner_txt">
                <h2> 人材トレンド2024｜期待のギャップ</h2>
                <p>国内の最新の調査結果をご覧ください。</p>
                <Link to="/">今すぐダウンロード(無料)</Link>
              </div>
            </div>

            <div className="main1_form_input">
              <div>
                <input
                  placeholder="ジョブタイトル、職種、キーワードなど"
                  class="form-autocomplete form-text ui-autocomplete-input"
                  type="text"
                  id="edit-search"
                  name="search"
                  value=""
                  size="30"
                  maxlength="128"
                />
                <input
                  placeholder="東京都、横浜市など..."
                  class="form-autocomplete form-text ui-autocomplete-input"
                  type="text"
                  id="edit-location"
                  name="location"
                  value=""
                  size="30"
                  maxlength="128"
                />
                <input
                  placeholder="最低年収"
                  class="form-autocomplete form-text ui-autocomplete-input"
                  type="text"
                  id="edit-location"
                  name="location"
                  value=""
                  size="30"
                  maxlength="128"
                  style={{ width: "140px" }}
                />
                <input
                  placeholder="最高年収"
                  class="form-autocomplete form-text ui-autocomplete-input"
                  type="text"
                  id="edit-location"
                  name="location"
                  value=""
                  size="30"
                  maxlength="128"
                  style={{ width: "140px" }}
                />
                <input
                  aria-label="検索"
                  name="Search"
                  type="submit"
                  id="edit-submit-job-search"
                  value="検索"
                  class="main1-form-submit"
                  style={{ width: "190px", marginRight: "0", padding: "0" }}
                />
              </div>
              <div className="main1_font">
                <font> 業界で検索 </font>
                <font> 分野で検索 </font>
                <font> 勤務地で検索 </font>
                <font> 役職名で検索 </font>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="main2_box">
        <div className="main2_box_inner">
          <div className="main2-tile-content">
            <h2>年収調査2024</h2>
            <p>最新の採用トレンドや職種別平均年収データを公開中</p>
            <Link to="/" class="btn btn-secondary-blue">
              {" "}
              今すぐダウンロード(無料){" "}
            </Link>
          </div>
        </div>
      </div>

      <div className="main3_box">
        <div className="main_inner">
          <div className="main3_box_top">
            <img alt="" src="/head/main1.png" />
          </div>

          <div className="main3_bot">
            <h2>新着記事</h2>
            <div>
              <div className="main3_bot_inner">
                <div className="main3_bot_body">
                  <div className="main3_internal">
                    <a href="/" className="main3_back">
                      <div className="main3_fileds">
                        <img
                          alt=""
                          src="https://www.michaelpage.co.jp/sites/michaelpage.co.jp/files/styles/advice_tile_desktop/public/2024-06/tt24-hpt.jpg.webp?itok=1rZcV9oM"
                        />
                      </div>
                      <h3>人材トレンド2024｜期待のギャップ</h3>
                      <div class="main3_teaser">
                        働く多くの人が注目している給与、フレキシビリティ、DE&amp;I、AIにおける企業の期待と従業員が直面している現実の間に広がる溝を理解して、今日の雇用市場で人材の雇用・定着を効率的に進めましょう。
                      </div>
                      <div className="main3-author-details">
                        筆者 Michael Page
                      </div>
                    </a>
                  </div>
                  <div className="main3_internal">
                    <a href="/" className="main3_back">
                      <div className="main3_fileds">
                        <img
                          alt=""
                          src="https://www.michaelpage.co.jp/sites/michaelpage.co.jp/files/styles/advice_tile_desktop/public/2024-03/17417-PI%20Salary%20Guide-HPT-370x220-JP.jpg.webp?itok=xmnRjWwy"
                        />
                      </div>
                      <h3>年収調査2024</h3>
                      <div class="main3_teaser">
                        中途採用者の年収を決める際や転職の目標年収を設定する際に参考としてご活用いただける職種別の平均年収データや最新の採用トレンドをまとめてご案内しています。ぜひレポートをダウンロードください。{" "}
                      </div>
                      <div className="main3-author-details">
                        筆者 Michael Page
                      </div>
                    </a>
                  </div>
                  <div className="main3_internal">
                    <a href="/" className="main3_back">
                      <div className="main3_fileds">
                        <img
                          alt=""
                          src="https://www.michaelpage.co.jp/sites/michaelpage.co.jp/files/styles/advice_tile_desktop/public/2024-10/AdobeStock_204674952.jpeg.webp?itok=yFQuxRPQ"
                        />
                      </div>
                      <h3>
                        ITエンジニアで海外転職はできる？メリットや注意点など解説
                      </h3>
                      <div class="main3_teaser">
                        ITエンジニアが活躍できる場所は、何も日本国内だけではありません。知識とスキル、経験さえあれば海外の企業で働くことも可能な点が、ITエンジニアの強味です。
                        しかし、海外で働くことに興味はあっても、どう仕事を探したらよいのか…{" "}
                      </div>
                      <div className="main3-author-details">
                        筆者 Michael Page 5 分程度で読めます
                      </div>
                    </a>
                  </div>

                  <div className="main3_internal">
                    <a href="/" className="main3_back">
                      <div className="main3_fileds">
                        <img
                          alt=""
                          src="https://www.michaelpage.co.jp/sites/michaelpage.co.jp/files/styles/advice_tile_desktop/public/2024-10/AdobeStock_486877969%20%281%29.jpeg.webp?itok=1D04N_iA"
                        />
                      </div>
                      <h3>
                        IT業界はなぜ人材不足？理由や人事・採用担当がとるべき対策について解説
                      </h3>
                      <div class="main3_teaser">
                        生産年齢人口の減少により、IT業界における人材不足は年々深刻化しています。競争力や生産性を維持するためにも、多くの企業が早急に対応せざるを得ない状況にあります。
                        さまざまな産業分野でIoTやDX…{" "}
                      </div>
                      <div className="main3-author-details">
                        筆者 Michael Page 5 分程度で読めます
                      </div>
                    </a>
                  </div>
                  <div className="main3_internal">
                    <a href="/" className="main3_back">
                      <div className="main3_fileds">
                        <img
                          alt=""
                          src="https://www.michaelpage.co.jp/sites/michaelpage.co.jp/files/styles/advice_tile_desktop/public/2024-03/17417-PI%20Salary%20Guide-HPT-370x220-JP.jpg.webp?itok=xmnRjWwy"
                        />
                      </div>
                      <h3>年収調査2024</h3>
                      <div class="main3_teaser">
                        中途採用者の年収を決める際や転職の目標年収を設定する際に参考としてご活用いただける職種別の平均年収データや最新の採用トレンドをまとめてご案内しています。ぜひレポートをダウンロードください。{" "}
                      </div>
                      <div className="main3-author-details">
                        筆者 Michael Page
                      </div>
                    </a>
                  </div>
                  <div className="main3_internal">
                    <a href="/" className="main3_back">
                      <div className="main3_fileds">
                        <img
                          alt=""
                          src="https://www.michaelpage.co.jp/sites/michaelpage.co.jp/files/styles/advice_tile_desktop/public/2024-10/AdobeStock_498091017.jpeg.webp?itok=WMjzH_Mk"
                        />
                      </div>
                      <h3>
                        Javaエンジニアとは？年収・仕事内容・将来性や転職方法などを解説
                      </h3>
                      <div class="main3_teaser">
                        Javaエンジニアは、IT業界で非常に重要な役割を担い、幅広い分野で高い需要があるJavaエンジニア。将来性も明るい職業として、未経験から目指す人も少なくありません。
                        Javaエンジニアを目指す際に、具体的な仕事内容や必要な知識、...{" "}
                      </div>
                      <div className="main3-author-details">
                        筆者 Michael Page 1 分程度で読めます
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="main4_box">
        <div className="main4_inner">
          <h2>注目の企業</h2>
          <div className="main4_ul">
            <ul>
              <li>
                <img
                  alt=""
                  src="https://www.michaelpage.co.jp/sites/michaelpage.co.jp/files/styles/employer_desktop_tile/public/2023-03/Untitled%20design%20%2819%29.jpg.webp?itok=GRDK0Emo"
                />
              </li>
              <li>
                <img
                  alt=""
                  src="https://www.michaelpage.co.jp/sites/michaelpage.co.jp/files/styles/employer_desktop_tile/public/2022-01/Untitled%20design%20%287%29.jpg.webp?itok=APB2UUxH"
                />
              </li>
            </ul>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Main;
