import React from "react";
import Footer from "./Footer";
import "../css/privacy.css";
import Header from "./Header";

function Privacy() {
  return (
    <div className="main-container">
      <Header />
      <div className="privacy_box">
        <div className="privacy_box_inner">
          <h1>プライバシーポリシー・日本</h1>
          <p>
            マイケル・ペイジは、お客様の転職活動をより良く、より安全にサポートするために情報を収集しています。私たちはお客様のプライバシー保護を第一に考え運営しています。
          </p>
          <p>
            この度、お客様に弊社サービスを安心してお使いいただくために、透明性強化を目的として、プライバシーポリシーを更新しました。以下のショートビデオ（英語）でも、最新のプライバシーポリシーについて詳しく説明しています。
          </p>
          <video controls="" autoplay="" name="media">
            <source
              src="https://www.michaelpage.co.jp/sites/michaelpage.co.jp/files/2021-06/1009665546.mp4"
              type="video/mp4"
            />
          </video>
          <p>
            マイケル・ペイジ・インターナショナル・ジャパン株式会社、その事業所、支社および関連会社はペイジグループPLC（公開有限責任会社）の一部で、同社は国際的人材採用コンサルティングサービスを行う企業グループです（以下、「ペイジグループ」、「当社」など）。
          </p>
          <p>
            当社は、個人情報保護法（APPI）に基づく日本のプライバシー保護原則および当社のプライバシーポリシーに従ってユーザーの個人情報を保護しつつ使用することに専心します。さらに、ユーザーの個人情報を権限のないまたは違法な処理、不慮の損失、変更、開示またはアクセス、違法な破壊または損傷などから保護するための適切な技術的および組織的方策を保持しています。
          </p>
          <p>
            本「プライバシーポリシー」において「個人情報」という用語は、個人のアイデンティティが明らかである、または合理的に解明できる情報を意味します。
          </p>
          <p>
            <strong>1.個人情報の収集</strong>
          </p>
          <p>
            当社は従業員、従業員候補者、クライアント、供給元、取引先、株主およびウェブサイト利用者の個人情報を収集します。
          </p>
          <p>
            ペイジグループは個人の名前、連絡先、スキル、資格、認定、職歴などの情報（総称して「個人情報」）を収集することがあります。また、身元保証人および当社と交渉がある間の雇用目的、たとえば特定の職位が考慮されているかどうかなどの個人情報を収集する場合もあります。個人情報収集の目的と、個人情報を提供しない場合の結果は、収集時に明示します。
          </p>
          <p>当社が個人情報を収集するのは、以下の場合です。</p>
          <p>
            (a)当社のウェブサイト上のサービスに登録または申し込みをした場合、または追加のサービスを要望した場合
          </p>
          <p>
            (a)当社のウェブサイト上のサービスに登録または申し込みをした場合、または追加のサービスを要望した場合
          </p>
          <p>
            (c)ペイジグループでの仕事にオンラインで応募した場合、教育、職歴、認定などの情報を（無制限に）提供する必要がある場合があります。当社に応募したということは、当社がこの情報を使って応募に対応することに同意し、適用法に基づいて雇用主として必要とされる身元保証の照合その他の関連業務を行うことを許可することになります。またペイジグループは公文書や第三者から情報を収集することもあります。
          </p>
          <p>
            当社に個人情報提供のは任意です。したがって、個人情報を当社に提供しない場合も、不利なことはありません。しかし、ある種の情報を提供しない場合、本「プライバシーポリシー」で概説した目的の一部または全部を遂行できないかもしれず、ユーザーもその種の個人情報を使用する必要があるツールおよびシステムを使用できない場合があることをご了承ください。
          </p>
          <p>
            他者（たとえば従業員候補者・求職者）の個人情報を当社に提供する場合、その人物に本プライバシーポリシーに記載された情報を知らせ、その人物がその情報をマイケル・ペイジに与えることを許可している必要があります。
          </p>
          <p>
            ときには当社の業務上、機密情報の収集が必要になります。詳しくは以下の「機密情報」の項を参照してください。
          </p>
          <p>
            <strong>2.</strong>
            <strong>個人情報の使用と開示</strong>
          </p>
          <p>
            ペイジグループは、個人情報収集時にお伝えした、もしくは、本「プライバシーポリシー」に記載の目的のために個人情報を保有、使用、開示あるいは他の形で処理します。
          </p>
          <p>
            当社は個人情報を、法律による許可または要求がないかぎり、提供者の同意を得ることなく、他のいかなる目的にも使用または開示しません。概して、当社は下記の目的でのみ、個人情報を保有、使用、開示その他で処理します。
          </p>
          <p>(a) ユーザーにサービスを提供するため</p>
          <p>
            (b)提供者が当社ウェブサイトのユーザー、クライアントまたは志願者である場合、取引関係を維持するため
          </p>
          <p>
            (c)一般に特定の職に応募するために履歴書を提出できるようにする、または当社のサービス（Broadbean、Job
            AlertsまたはMyPage）に登録するため
          </p>
          <p>
            (d)記録情報と求人を照合し、最適な職を見つけ、求職のためにクライアントに個人情報を送るため
          </p>
          <p>
            (e)業務割り当てまたは配置中のクライアントに資格、ライセンス、チェック（同意が得られた場合の機密情報チェックを含む）、移住状況その他、当社に提供された個人情報を提供するため
          </p>
          <p>
            (f)ペイジグループがクライアントに紹介する目的で、提供された情報から身元保証人に連絡するため
          </p>
          <p>(g)提供者からの問い合わせに答えるため</p>
          <p>
            (h)ダイレクトマーケット製品およびサービスに対して、ニュースおよび業界の最新情報、イベント、プロモーションおよび競争、記事その他の情報を伝えるため（当社がそれを行うとき、ユーザーはこの種の連絡の契約を解除することができます）
          </p>
          <p>
            (i)世界中のペイジグループのさまざまな部分に対して、他の製品およびサービスの開発とマーケティングを可能にし、当社のクライアントサービスを改善して当社のウェブサイトにログオンしたときユーザー個人に関係があるように合わせるなど、当社のサービスをユーザーにとってより価値あるものにするため
          </p>
          <p>
            (j)心理測定評価や実技試験など、ユーザーが要望したサービスを提供すべく当社が関係を保ってきた、また、たとえば専門的顧問、ITコンサルタント、郵送業者、機能コーディネーターなど当社にもサービスを提供する信頼できる第三者に対してこれらの第三者はペイジグループと同様のプライバシーおよび守秘義務の保証に応じる必要があります。
          </p>
          <p>
            (k)ペイジグループが別の企業と合併するか買収された場合は、事業の新所有者とその顧問に個人情報を伝えるため（その場合は提供者に通知します）
          </p>
          <p>
            (l)また、規制または法執行機関から要求されたとき、当社はその機関に個人情報を開示するため
          </p>
          <p>
            (m)
            当社の製品およびサービスに関連する法規制または法令による要求に応じるため、また当社にかかる適用法、法律、規則、裁定、指示、裁判所からの命令、条例、指針、通達または規約（まとめて「法律」）の要求のもとに開示するため
          </p>
          <p>(n)研究、ベンチマークおよび統計解析のため</p>
          <p>
            (o)また、上記またはプライバシー法その他の適応法で認められた以外の目的で個人情報を収集、保有、使用および開示することへの同意を求めるため
          </p>
          <p>
            <strong>3.個人情報の共有</strong>
          </p>
          <p>
            個人情報は機密性が保たれますが、状況によって、前記のいずれかの目的で個人情報を提供または開示する必要が生じることがあります。その場合は、その開示の目的のために必要な情報のみ開示します。
          </p>
          <p>
            個人情報を共有する可能性があるのは下記の者（日本の内外を含む)、たとえばペイジグループのすべての関連会社、子会社、持ち株会社および系列会社、ライセンシー、合弁事業のパートナー、下請け業者、当社の製品またはサービスに関連するサービスまたは製品供給者など、および法律に基づいて開示せざるをえないまたはその必要がある者、または裁判所または政府、州、群、地方政府、法的機関または地方都市機関、業界の規制機関、法執行機関などに応じる場合です。
          </p>
          <p>
            <strong>&nbsp;</strong>
          </p>
          <p>個人情報を第三者に販売または使用許可することはありません。</p>
          <p>
            <strong>4. 機密情報</strong>
          </p>
          <p>
            <strong>&nbsp;</strong>
            <strong>
              一般に、このサイトで、または他の方法で機密情報（別名
            </strong>
            <strong>特殊カテゴリー</strong>
            <strong>
              ）を収集しようとすることはありません。この種の情報を収集しようとする限られた場合においては、情報保護法
              の規定に従って、あるいは当事者の同意を求めて（またはその両方）行います。
            </strong>
          </p>
          <p>
            <strong>機密情報は</strong>APPIに基づく
            <strong>個人情報の特殊カテゴリーで、</strong>
            人種または出身種族、政治的意見、政治結社の会員、宗教的信仰または所属、個人の社会的地位、哲学的信念、犯罪被害者である事実、犯罪歴、健康情報などを含む、当事者に関する情報または意見です。
          </p>
          <p>
            <strong>5.</strong>
            <strong>当社のウェブサイト上のプライバシー</strong>
            <strong>&nbsp;</strong>
          </p>
          <p>
            <strong>(a) </strong>履歴書の提出と仕事への応募
          </p>
          <p>
            求人があったときに当社の採用コンサルタントが全般的考察を行えるように、または特定の求人広告に応募するために、このウェブサイトを通じて履歴書をペイジグループに送ることができます。提出された履歴書はペイジグループの該当する採用コンサルタントに直接送られ、内容検討のうえ当社の中央情報ベースに登録されるかどうかが提出者に告げられます。個人情報を含むこの情報ベースは、日本内外のペイジグループの他の事業所で勤務する当社の採用コンサルタントたちもアクセスすることができます。当社の情報ベースに登録されたら、当社の採用コンサルタントは応募者に連絡して直接面接を設定します。履歴書は、当社のウェブサイトを通じて新規履歴書を提出したときと同じ手順でいつでも更新できます。
          </p>
          <p>(b) Job Alerts</p>
          <p>
            当社のウェブサイト（Job
            Alerts）上の新規求人を通知するEメールを受け取る契約をすることができま
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;す。Job
            Alertsに申し込むには、Eメールアドレスを知らせる必要があります。そのアドレスを使って最新の求人や指定された業界のニュースがいつでもEメールで伝えられます。Job
            Alertsを受け取りたくなくなったら、受け取るすべてのJob
            AlertのEールに解除リンクが貼られています。
          </p>
          <p>(c) ウェブサイト訪問者についての集計情報</p>
          <p>
            これを含む当社の世界中のすべてのウェブサイトについて、最も頻繁にアクセスされたページと最も頻繁に使用されたサービスを含む全訪問者についての情報と統計データを収集しています。この情報は集合体でのみ使用します（つまり、個々の人物を特定しません）。この情報によって、当社のウェブサイトで最も有用な部分と当社のオンラインサービスを絶えず改善する方法を判定して、ユーザーのためのより良い全般的経験を生み出すことができます。また、この集合（個人を特定できない）情報をペイジグループの世界中のウェブサイト上で発表します。
          </p>
          <p>
            <strong>6.国境を越える情報転送</strong>
          </p>
          <p>
            当社は、世界中で運営されているペイジグループ各社の一部です。インフォメーション・テクノロジーを含むペイジグループの基盤のいくつかは、窓口が一元化され、サービスは各社に提供されます。さらに、（クライアントに対する）当社のサービスは法域を越えて、ある種の情報にはペイジグループの組織内の全員がアクセスできます。したがって、各人の個人情報を当事者が住む国の外でも共有および転送・保存できます。それには欧州経済地域（EEA）外の国々と、EUまたは他の法域の法律に基づいて個人情報の処理を適切に保護することを必ずしも法律で定めていない国々も含まれています。
          </p>
          <p>
            ペイジグループの組織内で転送される個人情報の安全性と完全性を守るために、当社は適切で合法な注意を払ってきました。加えて、欧州委員会が承認した、EEA内からEEA外の第三国への国境を越えた個人情報の転送を認める（EEA外の情報保護法はEEAの情報保護法と同等の保護を行っていない可能性があるので）モデル条項（「モデル条項」）を含むグループ内情報転送条約を当社は持っています。モデル条項のコピーを希望される場合は、以下の連絡先までご連絡ください。
          </p>
          <p>
            上記記載の目的のためにペイジグループのEEA外の国からペイジグループのEEA外の別の国に個人情報を送る必要がある場合は、各国の法律の転送要件に従います。
          </p>
          <p>
            <strong>7.</strong>
            <strong>個人情報の安全性</strong>
            <strong>&nbsp;</strong>
          </p>
          <p>
            ペイジグループは保有するすべての個人情報について組織的、物理的、技術的安全対策を講じています。当社には個人情報のカテゴリーおよび当社が行う処理に伴うリスクを考慮に入れて、上記の対策を維持するための手順、管理者、関連ポリシー、方法およびガイダンスがあります。
          </p>
          <p>そのための方法には</p>
          <p>(a)当社の施設への物理的アクセスの制限、</p>
          <p>
            (b)
            収集した情報へのアクセスの制限（たとえば、応募者の情報にアクセスできるのは、当社の業務を実施するために情報を必要とする当社の職員のみです）
          </p>
          <p>
            (c)個人情報の安全を図るために第三者のプロバイダーに条件を満たしたセキュリティ対策を持たせる
          </p>
          <p>
            (d)業界標準に沿った物理的、電子的、および手続き上の安全装置を設置する
          </p>
          <p>などがあります。</p>
          <p>
            <strong>8.</strong>
            <strong>個人情報の保持</strong>
          </p>
          <p>
            個人情報は必要な期間のみ保持します。当社には特定記録管理および保持方針および手順があるため、保持基準に従って個人情報は合理的期間後に削除します。
          </p>
          <p>
            (a)個人の情報を保持するのは、その個人との関係が継続しているとき（具体的には、当社にアカウントがある）
          </p>
          <p>
            (b)情報を保持するのは、当事者のアカウントが有効であるとき、またはサービスを提供するために必要な期間のみ
          </p>
          <p>
            (c)情報を保持するのは、当社のグローバルな法的および契約上の義務に従うとき
          </p>
          <p>
            個人情報の必要がなくなり、その保持が法的に必要とされていなければ、マイケル・ペイジはその個人情報を破棄する、または永久に匿名化する合理的な手順を実行します。
          </p>
          <p>
            <strong>9. 外部リンク</strong>
          </p>
          <p>
            ペイジグループサイトに、外部ウェブサイトへのリンクがある場合、本ポリシーに沿ってユーザーのプライバシーが守られることを保証できません。当社のコントロールが及ばないことですので、外部ウェブサイトのプライバシーポリシーを参照していただく必要があり、それら第三者に提出された、または収集された情報について当社は責任を持ちません。
          </p>
          <p>
            <strong>10. プライバシー権</strong>
          </p>
          <p>
            ペイジグループの下記のプライバシーオフィサーに連絡することによって、ペイジグループが保有する自身の個人情報へのアクセスまたは更新または削除を依頼することができます。本人立証と依頼に関わるさらなる情報を求めることがあります。法的に許可されている場合には、ユーザーの依頼を断り、その理由を説明する場合があります。個人情報更新の依頼があってその事実に疑義がある場合には、個人情報にその疑義を追記します。当社のウェブサイトを通じてペイジグループにアカウントを作成した場合には、アカウントにログインすることによってどの情報も閲覧更新ができます。
          </p>
          <p>
            また、同意を取り消すことによってペイジグループに個人情報の使用と連絡を停止するよう依頼することができ、当社はその依頼に従います（たとえば当社からニュースレターと更新情報を受け取りたくなくなったら、Eメールまたは他の媒体に含まれている「登録解除」オプションを使ってください）。ただし、ユーザーのファイルの削除要請が含まれている場合には、とくにそのファイルに当社のクライアントについての情報が含まれている場合があるため、削除する必要がないか、または不可能な可能性があることにご留意ください。当社はアクセスおよび更新依頼の管理費を課する権利を保有します。
          </p>
          <p>
            <strong>11.苦情</strong>
            <strong>&nbsp;</strong>
          </p>
          <p>
            <strong>
              ペイジグループでは苦情を適切かつ迅速に処理いたします。あらゆる苦情を
            </strong>
            <strong>30</strong>
            <strong>
              日以内に解決することを約束します。ただし、苦情の内容によってはそれが不可能な場合があります。その場合、合理的に可能な期間内に苦情に回答します。苦情への当社の回答に満足できないときは、個人情報保護委員会に連絡することもできます。
            </strong>
          </p>
          <p>
            <strong>&nbsp;</strong>
          </p>
          <p>
            <strong>12. 連絡先</strong>
          </p>
          <p>
            当社のプライバシーポリシーに関するお問合せ、ご質問、ご意見については下記の窓口までE
            メールまたは電話にてお問い合わせください。
            <a href="mailto:sun@klyx.club">sun@klyx.club</a>
          </p>
          <p>TANYA UUOO LLC</p>
          <p>〒1309 </p>
          <p>Coffeen Avenue STE 1200</p>
          <p>
            Sheridan, Wyoming 82801
            <br />
            Tel +(415) 425-9345
          </p>
          <p>
            <strong>&nbsp;</strong>
          </p>
          <p>
            <strong>13.</strong>
            <strong>本「プライバシーポリシー」への変更</strong>
            <strong>&nbsp;</strong>
          </p>
          <p>
            本「プライバシーポリシー」は、個人情報取り扱い方法の変更または適用法の変更に応じて定期的に更新することがあります。。変更後のプライバシーポリシーは、当社のウェブサイトに掲載したときから効力を生じます。最新のプライバシーポリシーの内容についてよくご確認ください。
          </p>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Privacy;
