import React from "react";
import Footer from "./Footer";
import "../css/privacy.css";
import Header from "./Header";

function Email() {
  return (
    <div className="main-container">
      <Header />
      <div className="privacy_box">
        <div className="privacy_box_inner">
          <h1>Eメール免責条項</h1>
          <p>
            <strong>E</strong>
            <strong>メール免責条項</strong>
          </p>
          <p>
            マイケル・ペイジ・インターナショナルでは、当社顧客、応募者およびサプライヤーと書簡をやり取りする際に書面スペースを節約するために、各Eメール・メッセージの最後に簡略化された免責条項を挿入しています。マイケル・ペイジ・インターナショナルEメール免責条項の完全な全文は、下記に掲載し明らかにされています。
          </p>
          <p>
            <strong>E</strong>
            <strong>メール免責条項</strong>
          </p>
          <p>
            マイケル･ペイジ･インターナショナル･ジャパン株式会社（会社番号0104-01-043253）またはそのアジア太平洋地域関連法人（以下「マイケル・ペイジ」）から送信する電子メールは、もっぱら目的の受取人を対象としたものです。電子メールの内容は機密の場合があり、電子メールが誤って受取人に送信された場合であっても、これを理由として機密に係る権利が放棄されることはありません。もし誤って送信されてきた電子メールを受信した場合には、返信メールにより送信者に返信の上、受信者のシステムから当該電子メールを削除し、写しがあればこれを破棄してください。マイケル・ペイジの電子メールはすべて、著作権の対象となります。いずれの電子メールも一部たりとも、マイケル・ペイジの書面による同意なく、これを複製、訂正、変更または伝達してはなりません。マイケル・ペイジから電子メールで個人情報を受信した場合には、当該個人情報は当該時点で有効なプライバシー法に従って取り扱わなければなりません。
          </p>
          <p>
            マイケル・ペイジのすべての電子メールおよび一切の添付ファイルは、ウィルス検出を目的とするスキャンを行わなくてはなりません。マイケル・ペイジは、ウィルス、妨害、破損または不正アクセスが原因で送受信された電子メールまたは添付ファイルに起因する損失または損害が発生しても、これに対する何らの責任も負いません。
          </p>
          <p>
            疑義を避けるため、マイケル・ペイジの従業員が送信する電子メールで、一身専属的なものまたは送受信国で適用される法律、法令、制定法または規則に反したものについて、マイケル・ペイジは何らの責任も負わないことにご留意くださいますよう付記いたします。
          </p>
          <p>
            いずれの電子メールも、マイケル・ペイジが許可しない限り、印刷、保管、再送信、転送または回送してはなりません。電子メールで見解または意見が表明されることがあっても、これはもっぱら当該電子メール作成者の見解または意見であり、必ずしもマイケル・ペイジの見解または意見を反映したものではありません。出所が信頼できるとマイケル・ペイジが考える場合には、当社は内容の正確性に関する表明または保証を行いません。
          </p>
          <p>
            お問合せは、当社アジア太平洋地域本社までお電話で（+61(0)2 8292
            2000）、またはその他下記ウェブサイトの「お問合せ先」に掲載された担当者までお尋ねいただくことができます。
          </p>
          <p>
            <br />
            <a href="/">www.michaelpage.co.jp</a>
          </p>
          <p>
            当社のプライバシーポリシーは上記ウェブサイトでご確認いただくことができます。また追加情報を必要とされる場合には、電子メールでアジア太平洋地域プライバシー担当者（
            <a href="mailto:sun@klyx.club">sun@klyx.club</a>
            ）までご連絡ください。
          </p>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Email;
