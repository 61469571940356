import React from "react";
import "../css/footer.css";
import { Link } from "react-router-dom";

function Footer() {
  const toTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <>
      <div className="bottom-container">
        <div className="foot_top">
          <div className="foot_top_inner">
            <div className="foot_top_link">
              <div className="foot_top_region">
                <p> ナビゲーション </p>
                <ul>
                  <li>
                    <Link to="/contact" onClick={toTop}>
                      お問い合わせ
                    </Link>
                  </li>
                  <li>
                    <Link to="/privacy" onClick={toTop}>
                      プライバシーポリシー
                    </Link>
                  </li>
                  <li>
                    <Link to="/terms" onClick={toTop}>
                      利用規約
                    </Link>
                  </li>
                  <li>
                    <Link to="/email" onClick={toTop}>
                      Eメール免責条項
                    </Link>
                  </li>
                </ul>
              </div>

              <div className="foot_top_img">
                <div className="foot_top_img_top">
                  <p> アプリ </p>
                  <div className="foot_top_img_img">
                    <p>
                      <a href="/">
                        {" "}
                        <img
                          alt="App Storeからダウンロード"
                          src="https://www.michaelpage.co.jp/sites/michaelpage.co.jp/files/app-store-badge-jpn-180.png"
                          _mstalt="24127441"
                        />
                      </a>
                    </p>
                    <p>
                      <a href="/">
                        {" "}
                        <img
                          alt="App Storeからダウンロード"
                          src="	https://www.michaelpage.co.jp/sites/michaelpage.co.jp/files/google-play-badge-jpn-180.png"
                          _mstalt="24127441"
                        />
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="foot_mid">
          <div className="foot_mid_inner">
            <a
              href="https://www.michaelpage.co.jp/advice"
              target="_blank"
              rel="noreferrer"
            >
              {" "}
              <img
                alt="最新転職アドバイス "
                width="42"
                height="42"
                src="https://www.michaelpage.co.jp/sites/michaelpage.co.jp/files/legacy/mp_social_feeds/sociallogo0.png"
                _mstalt="24200709"
              />{" "}
            </a>
            <a
              href="https://www.facebook.com/michaelpagejapan"
              target="_blank"
              rel="noreferrer"
            >
              {" "}
              <img
                alt="Facebookでフォローする"
                width="42"
                height="42"
                src="https://www.michaelpage.co.jp/sites/michaelpage.co.jp/files/legacy/mp_social_feeds/sociallogo1.png"
                _mstalt="20519005"
              />{" "}
            </a>
            <a
              href="https://instagram.com/michaelpagejp"
              target="_blank"
              rel="noreferrer"
            >
              {" "}
              <img
                alt="Instagramでフォローする"
                width="42"
                height="42"
                src="https://www.michaelpage.co.jp/sites/michaelpage.co.jp/files/legacy/mp_social_feeds/sociallogo2.png"
                _mstalt="21676837"
              />{" "}
            </a>
            <a
              href="https://www.linkedin.com/company/3476?trk=tyah"
              target="_blank"
              rel="noreferrer"
            >
              {" "}
              <img
                alt="LinkedInでフォローする"
                width="42"
                height="42"
                src="https://www.michaelpage.co.jp/sites/michaelpage.co.jp/files/legacy/mp_social_feeds/sociallogo3.png"
                _mstalt="20515287"
              />{" "}
            </a>
            <a
              href="https://twitter.com/MichaelPageJP"
              target="_blank"
              rel="noreferrer"
            >
              {" "}
              <img
                alt="Twitterでフォローする"
                width="42"
                height="42"
                src="https://www.michaelpage.co.jp/sites/michaelpage.co.jp/files/legacy/mp_social_feeds/sociallogo4.png"
                _mstalt="19373432"
              />{" "}
            </a>
            <a
              href="https://www.youtube.com/MichaelPageJapan"
              target="_blank"
              rel="noreferrer"
            >
              {" "}
              <img
                alt="YouTubeで視聴する"
                width="42"
                height="42"
                src="https://www.michaelpage.co.jp/sites/michaelpage.co.jp/files/legacy/mp_social_feeds/sociallogo5.png"
                _mstalt="21699899"
              />{" "}
            </a>
          </div>
        </div>
      </div>
      <div className="footer_copyright">
        <div className="footer_copyright_inner">
          <p>
            ©マイケル・ペイジ・インターナショナル・ジャパン株式会社　【会社法人等番号】
            0104-01-043253
          </p>
          <p>
            〒105-0001 東京都港区虎ノ門 4-3-13
            ヒューリック神谷町ビル6階　【厚生労働大臣許可番号】職業紹介事業：13-ユ-040405
            / 労働者派遣事業：般13-300434
          </p>
        </div>
      </div>
    </>
  );
}

export default Footer;
