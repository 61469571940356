import React from "react";
import { Link } from "react-router-dom";
import "../css/header.css";

function Header() {
  const toTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div className="header-container">
      <div className="head_top">
        <div className="head_top_inner">
          <Link to="/" onClick={toTop}>
            <img
              width="245"
              height="45"
              src="https://www.michaelpage.co.jp/themes/custom/mp_theme/logo.png"
              alt="外資系転職のマイケル・ペイジ | 世界大手の転職エージェント"
            />
          </Link>
        </div>
      </div>

      <div className="head_bot">
        <div className="head_bot_inner">
          <ul>
            <li>
              <Link to="/" onClick={toTop}>
                家
              </Link>
            </li>
            <li>
              <Link to="/contact" onClick={toTop}>
                お問い合わせ
              </Link>
            </li>
            <li>
              <Link to="/privacy" onClick={toTop}>
                プライバシーポリシー
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Header;
