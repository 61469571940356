import React, { useState } from "react";
import Footer from "./Footer";
import "../css/contact.css";
import Header from "./Header";
import { Link } from "react-router-dom";

function Contact() {
  const [formErrors, setFormErrors] = useState({});
  const [formData, setFormData] = useState({
    surname: "",
    name: "",
    emailAdd: "",
    post: "",
    companyName: "",
    tel: "",
    industry: "",
    hiring: "",
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };
  const handleFormSubmit = (e) => {
    e.preventDefault();
    const errors = {};
    window.scrollTo({
      top: 560,
      behavior: "smooth",
    });

    if (!formData.surname) errors.surname = "あなたの姓を入力";
    if (!formData.name) errors.name = "お名前を入力してください";
    if (!formData.emailAdd)
      errors.emailAdd = "仕事用のメールアドレスを入力してください";
    if (!formData.post) errors.post = "役職を入力してください";
    if (!formData.companyName) errors.companyName = "会社名を入力してください";
    if (!formData.tel) errors.tel = "電話番号を入力してください";
    if (!formData.industry) errors.industry = "オプションを選んでください";
    if (!formData.hiring) errors.hiring = "オプションを選んでください";

    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      return;
    }

    alert("送信成功！");
    setFormData({
      surname: "",
      name: "",
      emailAdd: "",
      post: "",
      companyName: "",
      tel: "",
      industry: "",
      hiring: "",
    });
    setFormErrors({});
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  const toTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div className="main-container">
      <Header />
      <div className="contact1_box">
        <hr />
        <div className="contact1_box_inner">
          <div className="contact1_body">
            <div className="contact1_body_txt">
              <div class="banner-sub-text"> お問い合わせ</div>
              <h1>
                <p>採用をお考えですか？</p>
              </h1>
            </div>
          </div>
        </div>
      </div>

      <div className="contact2_box">
        <div className="contact2_box_inner">
          <div className="contact2_body">
            <ul>
              <li>
                <div className="contact2_body1">
                  <div className="employer-jobseeker-description">
                    <h3 class="employer-jobseeker-title">
                      弊社では正社員・派遣ともに貴社のニーズに合ったスキルを備えた適切な候補者を発掘します。
                    </h3>
                    <div id="callbackform">
                      <p>
                        <font _mstmutation="1">
                          転職活動中の方は、{" "}
                          <a href="/job-search" _mstmutation="1">
                            <strong>こちら</strong>
                          </a>
                          から求人を検索ください。
                        </font>
                        <br />
                        &nbsp;
                      </p>
                      <div className="contact_webform">
                        <form>
                          <div className="contact_input">
                            {" "}
                            <label
                              for="edit-first-name"
                              class="js-form-required form-required"
                            >
                              姓
                            </label>{" "}
                            <input
                              type="text"
                              id="edit-first-name"
                              name="surname"
                              value={formData.surname}
                              onChange={handleChange}
                              size="60"
                              maxlength="255"
                              placeholder="姓"
                              class="form-text required"
                              required="required"
                              style={{
                                border: formErrors.surname
                                  ? "2px solid #be1200"
                                  : "1px solid #706f6f",
                              }}
                            />
                            <br />
                            {formErrors.surname && (
                              <p className="error">{formErrors.surname}</p>
                            )}
                          </div>
                          <div class="contact_input">
                            {" "}
                            <label
                              for="edit-last-name"
                              class="js-form-required form-required"
                            >
                              名
                            </label>{" "}
                            <input
                              type="text"
                              id="edit-last-name"
                              name="name"
                              value={formData.name}
                              onChange={handleChange}
                              size="60"
                              maxlength="255"
                              placeholder="名"
                              class="form-text required"
                              required="required"
                              style={{
                                border: formErrors.name
                                  ? "2px solid #be1200"
                                  : "1px solid #706f6f",
                              }}
                            />
                            <br />
                            {formErrors.name && (
                              <p className="error">{formErrors.name}</p>
                            )}
                          </div>
                          <div class="contact_input">
                            {" "}
                            <label
                              for="edit-corporate-email"
                              class="js-form-required form-required"
                            >
                              メールアドレス
                            </label>{" "}
                            <input
                              type="email"
                              id="edit-corporate-email"
                              name="emailAdd"
                              value={formData.emailAdd}
                              onChange={handleChange}
                              size="60"
                              maxlength="254"
                              placeholder="メールアドレス"
                              class="form-email required"
                              required="required"
                              style={{
                                border: formErrors.emailAdd
                                  ? "2px solid #be1200"
                                  : "1px solid #706f6f",
                              }}
                            />
                            <br />
                            {formErrors.emailAdd && (
                              <p className="error">{formErrors.emailAdd}</p>
                            )}
                          </div>
                          <div class="contact_input">
                            {" "}
                            <label
                              for="edit-job-title"
                              class="js-form-required form-required"
                            >
                              役職
                            </label>{" "}
                            <input
                              type="text"
                              id="edit-job-title"
                              name="post"
                              value={formData.post}
                              onChange={handleChange}
                              size="60"
                              maxlength="255"
                              placeholder="役職"
                              class="form-text required"
                              required="required"
                              style={{
                                border: formErrors.post
                                  ? "2px solid #be1200"
                                  : "1px solid #706f6f",
                              }}
                            />
                            <br />
                            {formErrors.post && (
                              <p className="error">{formErrors.post}</p>
                            )}
                          </div>
                          <div class="contact_input">
                            {" "}
                            <label
                              for="edit-company"
                              class="js-form-required form-required"
                            >
                              会社名
                            </label>{" "}
                            <input
                              type="text"
                              id="edit-company"
                              name="companyName"
                              value={formData.companyName}
                              onChange={handleChange}
                              size="60"
                              maxlength="255"
                              placeholder="会社名"
                              class="form-text required"
                              required="required"
                              style={{
                                border: formErrors.companyName
                                  ? "2px solid #be1200"
                                  : "1px solid #706f6f",
                              }}
                            />
                            <br />
                            {formErrors.companyName && (
                              <p className="error">{formErrors.companyName}</p>
                            )}
                          </div>
                          <div class="contact_input">
                            {" "}
                            <label
                              for="edit-telephone"
                              class="js-form-required form-required"
                            >
                              電話番号
                            </label>{" "}
                            <input
                              type="text"
                              id="edit-telephone"
                              name="tel"
                              value={formData.tel}
                              onChange={handleChange}
                              placeholder="電話番号"
                              class="form-number required"
                              required="required"
                              style={{
                                border: formErrors.tel
                                  ? "2px solid #be1200"
                                  : "1px solid #706f6f",
                              }}
                            />
                            <br />
                            {formErrors.tel && (
                              <p className="error">{formErrors.tel}</p>
                            )}
                          </div>
                          <div class="contact_input">
                            {" "}
                            <label
                              for="edit-sector"
                              class="js-form-required form-required"
                            >
                              業界
                            </label>{" "}
                            <select
                              id="edit-sector"
                              name="industry"
                              class="form-select required"
                              required="required"
                              value={formData.industry}
                              onChange={handleChange}
                              style={{
                                border: formErrors.industry
                                  ? "2px solid #be1200"
                                  : "1px solid #706f6f",
                              }}
                            >
                              <option value="" selected="selected">
                                - 選択 -
                              </option>
                              <option value="24186">経理・財務</option>
                              <option value="24191">派遣・契約</option>
                              <option value="24196">カスタマーサービス</option>
                              <option value="24201">デジタル</option>
                              <option value="24211">
                                エグゼクティブサーチ
                              </option>
                              <option value="24216">金融</option>
                              <option value="24221">医療・ヘルスケア</option>
                              <option value="24226">人事</option>
                              <option value="24231">IT</option>
                              <option value="24236">法務</option>
                              <option value="24241">ライフサイエンス</option>
                              <option value="24246">マーケティング</option>
                              <option value="24251">オフィスサポート</option>
                              <option value="24256">
                                購買・サプライチェーン
                              </option>
                              <option value="24261">不動産</option>
                              <option value="24266">小売</option>
                              <option value="24271">営業</option>
                            </select>
                            {formErrors.industry && (
                              <p className="error">{formErrors.industry}</p>
                            )}
                          </div>
                          <div class="contact_input">
                            {" "}
                            <label
                              for="edit-how-will-you-use-this-report-"
                              class="js-form-required form-required"
                            >
                              採用をお考えですか？
                            </label>{" "}
                            <select
                              id="edit-how-will-you-use-this-report-"
                              name="hiring"
                              class="form-select required"
                              required="required"
                              value={formData.hiring}
                              onChange={handleChange}
                              style={{
                                border: formErrors.hiring
                                  ? "2px solid #be1200"
                                  : "1px solid #706f6f",
                              }}
                            >
                              <option value="" selected="selected">
                                - 選択 -
                              </option>
                              <option value="Looking to hire">
                                はい、採用を考えています
                              </option>
                              <option value="Looking for a job">
                                いいえ、仕事を探しています
                              </option>
                            </select>
                            {formErrors.hiring && (
                              <p className="error">{formErrors.hiring}</p>
                            )}
                          </div>
                          <input
                            type="submit"
                            id="edit-actions-submit"
                            name="op"
                            value="送信"
                            class="button button--primary js-form-submit form-submit"
                            onClick={handleFormSubmit}
                          />
                        </form>
                        <p>&nbsp;</p>
                        <p className="contact_form_p">
                          Important: By submitting your email address and other
                          personal information to this website, you consent to
                          the collection, retention, use and disclosure of such
                          information in accordance with our{" "}
                          <Link to="/privacy" onClick={toTop}>
                            Privacy Policy
                          </Link>{" "}
                          and the{" "}
                          <Link to="/terms" onClick={toTop}>
                            website's terms of use
                          </Link>
                          .
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <div className="contact_job_seeker">
                  <div className="employer-jobseeker-description">
                    <h3>Testimonials</h3>
                    <div
                      style={{
                        backgroundColor: "rgba(250,166,26,0.1)",
                        borderRadius: "10px",
                        margin: "20px 0 20px 0",
                        padding: "40px 20px",
                      }}
                    >
                      <p>
                        <span
                          style={{
                            color: "#FAA61A",
                            fontSize: "24px",
                            lineHeight: "20px",
                            marginRight: "0",
                            paddingBottom: "10px",
                            textRendering: "auto",
                          }}
                        >
                          <strong>"</strong>
                        </span>
                        <font
                          _mstmutation="1"
                          _msttexthash="13415636"
                          _msthash="600"
                        >
                          {" "}
                          We are very pleased with your engagement process and
                          our ability to adapt to our speed. It was very fresh,
                          I didn't lose any time, and I was able to find great
                          people.
                        </font>
                        <span
                          style={{
                            color: "#FAA61A",
                            fontSize: "24px",
                            lineHeight: "20px",
                            marginRight: "0",
                            paddingBottom: "10px",
                            textRendering: "auto",
                          }}
                        >
                          <strong>"</strong>
                        </span>
                      </p>
                      <div style={{ margin: "20px 0 0 0" }}>
                        <strong _msttexthash="208949" _msthash="601">
                          Consult Group
                        </strong>
                      </div>
                    </div>

                    <div
                      style={{
                        backgroundColor: "rgba(55,190,236,0.1)",
                        borderRadius: "10px",
                        margin: "20px 0 20px 0",
                        padding: "40px 20px",
                      }}
                    >
                      <p>
                        <span
                          style={{
                            color: "#37BEEC",
                            fontSize: "24px",
                            lineHeight: "20px",
                            marginRight: "0",
                            paddingBottom: "10px",
                            textRendering: "auto",
                          }}
                        >
                          <strong>"</strong>
                        </span>
                        <font
                          _mstmutation="1"
                          _msttexthash="14734551"
                          _msthash="602"
                        >
                          {" "}
                          I would like to continue to use Michael Page as the
                          professional recruitment agency we want to use, and if
                          my own circumstances require it, I will not hesitate
                          to use him.
                        </font>
                        <span
                          style={{
                            color: "#37BEEC",
                            fontSize: "24px",
                            lineHeight: "20px",
                            marginRight: "0",
                            paddingBottom: "10px",
                            textRendering: "auto",
                          }}
                        >
                          <strong>"</strong>
                        </span>
                      </p>
                      <div style={{ margin: "20px 0 0 0" }}>
                        <strong _msttexthash="76765" _msthash="603">
                          Hertel
                        </strong>
                      </div>
                    </div>

                    <div style={{ textAlign: "center" }}>
                      <img
                        src="	https://www.michaelpage.co.jp/sites/michaelpage.co.jp/files/2023-12/diversity-inclusion.png"
                        alt="ダイバーシティとインクルージョン"
                        width="150"
                        height="150"
                        loading="lazy"
                        _mstalt="37674598"
                      />
                      <br />
                      <h4
                        class="text-align-center"
                        style={{ fontSize: "18px", marginTop: "0px" }}
                        _msttexthash="409656"
                        _msthash="604"
                      >
                        Diversity &amp; Inclusion
                      </h4>
                      <p
                        class="text-align-center"
                        style={{ marginBottom: "20px" }}
                        _msttexthash="4297917"
                        _msthash="605"
                      >
                        Leverage our industry-recognized D&amp;I program to
                        create truly inclusive employment.
                      </p>
                    </div>
                    <div style={{ textAlign: "center" }}>
                      <img
                        src="https://www.michaelpage.co.jp/sites/michaelpage.co.jp/files/2023-12/leading-technology.png"
                        alt="最先端のテクノロジー"
                        width="150"
                        height="150"
                        loading="lazy"
                        _mstalt="23018489"
                      />
                      <br />
                      <h4
                        class="text-align-center"
                        style={{ fontSize: "18px", marginTop: "0px" }}
                        _msttexthash="673374"
                        _msthash="606"
                      >
                        State-of-the-art technology
                      </h4>
                      <p
                        class="text-align-center"
                        style={{ marginBottom: "20px" }}
                        _msttexthash="4888949"
                        _msthash="607"
                      >
                        Leverage our state-of-the-art platform to reach the
                        right candidates at a wide range of jobs.
                      </p>
                    </div>
                    <div style={{ textAlign: "center" }}>
                      <img
                        src="https://www.michaelpage.co.jp/sites/michaelpage.co.jp/files/2023-12/access-to-unique-data.png"
                        alt="インサイト主導"
                        width="150"
                        height="150"
                        loading="lazy"
                        _mstalt="14406639"
                      />
                      <br />
                      <h4
                        class="text-align-center"
                        style={{ fontSize: "18px", marginTop: "0px" }}
                        _msttexthash="251511"
                        _msthash="608"
                      >
                        Insight-driven
                      </h4>
                      <p
                        class="text-align-center"
                        _msttexthash="7649226"
                        _msthash="609"
                      >
                        Gain valuable intelligence through Page Insights, which
                        leverages a wide range of internal and external market
                        data.
                      </p>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Contact;
