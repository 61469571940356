import React from "react";
import Footer from "./Footer";
import "../css/privacy.css";
import Header from "./Header";

function Terms() {
  return (
    <div className="main-container">
      <Header />
      <div className="privacy_box">
        <div className="privacy_box_inner">
          <h1>利用規約</h1>
          <p>
            本利用規約は、当社プライバシーポリシーと合わせて、ウェブサイトmichaelpage.co.jpの利用に適用されます。本ウェブサイトを利用されることにより、随時適用される本利用規約の遵守にご同意いただくことになります。ご同意いただけない場合には、本ウェブサイトのご利用をご遠慮いただきますようお願いいたします。
            <br />
            また当社リクルートメントサービスおよび当社との取引関係には、顧客および応募者を対象とした当社の基本規約も適用されます。同規約は、ご利用者の就職上のご要望をお伺いする際に、当社専門コンサルタントからお知らせいたします。
          </p>
          <p>
            当社は本規約を当社の裁量で通知せずに変更することができます。したがいまして、定期的に本ページにアクセスしていただき、最新の変更内容を把握いただくようにお勧めいたします。
          </p>
          <p>
            <strong>ウェブサイトの利用</strong>
            <br />
            本ウェブサイトの目的は、当社のサービスおよび一般的な情報を知らせることにあります。下記に定める「認められる利用に関する規則」の条件に決して違反されないようにしていただく必要があります。
          </p>
          <p>
            <strong>他のサイトにまたは他のサイトからつながるリンク</strong>
          </p>
          <p>
            本サイトには外部のウェブサイトにつながるリンクが掲載されており、ご利用者の関心を引く他のサイトへの自動リンクが多数、張られていることがあります。ペイジグループでは、そうした外部サイトのコンテンツに関する責任を負うものではなく、またペイジグループでそうしたサイトまたはそのコンテンツの審査または承認を行ったものとみなしてはならず、当社はそうした外部サイトにつながるリンクが機能しまたは最新のものであることを保証するものではありません。
          </p>
          <p>
            <strong>免責</strong>
            <br />
            本ウェブサイトの情報および資料は、一般的な指針を提供するものにすぎません。ペイジグループでは本ウェブサイト上の情報および資料の編集および提示にあたって相当な注意を行使していますが、当社は本ウェブサイトの正確性、現在性、完全性、妥当性、適合性もしくは運営、当社が本ウェブサイトを通じて提供するサービス、または本ウェブサイトに含まれる情報もしくは資料に関して、いかなる種類の表明または保証も行いません。
            <br />
            法律上最大限許される限りで、ペイジグループは下記事項に対する責任を負わないものとします。
          </p>
          <p>
            (a)&nbsp;本ウェブサイトまたはそのコンテンツのいずれかにアクセスしまたは使用したことに直接または間接に起因して被ったまたは負った損失、責任または損害
          </p>
          <p>
            (b)&nbsp;本ウェブサイトに掲載されたもしくは掲載されていない情報もしくは資料に依拠すること、またはかかる情報もしくは資料に基づき行われた決定
          </p>
          <p>
            (c)&nbsp;本ウェブサイトで広告された就職口、欠員、職務またはその他雇用もしくは任務の存在または利用可能性に関する表明など
          </p>
          <p>
            (d)&nbsp;雇用者もしくは顧客が応募者の情報を依頼しもしくは応募者の面接もしくは雇用を依頼する見込み、または応募者が紹介可能な見込みもしくは雇用者もしくは顧客の要望に合う見込みがあるという表明など
          </p>
          <p>
            (e)
            ペイジグループの合理的な支配の及ばない事情を原因とするペイジグループの本規約違反
          </p>
          <p>
            (f)&nbsp;本ウェブサイトの断続的な障害または修復、保守もしくは新しい設備もしくはサービスの導入に起因する、本ウェブサイトおよび本ウェブサイトを通じて提供するいずれかのサービスの故障、遅延、中断または利用不能
          </p>
          <p>
            (h)&nbsp;本ウェブサイトを通じて提供するデータにワーム、ウィルス、トロイの木馬またはその他通信に悪影響を及ぼしうるコンピュータコードが一切含まれていないという表明
          </p>
          <p>
            (k)&nbsp;本ウェブサイトからアクセス可能な第三者が運営するウェブサイトに掲載された情報または資料
          </p>
          <p>
            いずれかの保証を除外することができない場合には、法律上最大限許される限りで、ペイジグループの負う責任は、当社の選択に応じて、適切なサービスの再供給、サービスの再供給費用またはサービスの修復費用の負担に限られるものとします。いかなる場合もペイジグループは、間接的な、派生的な、付随的な、懲罰的なまたは特別の損害、損失、責任または費用に対する責任を負いません。
          </p>
          <p>
            <strong>当社ウェブサイトへの登録</strong>
            <br />
            ご利用者は、各自の履歴書をアップロードもしくは提出し、求人に応募し、求人アラートに登録し、またはマイページアカウントを作成するときには、その際またはその他の機会に提供する詳細情報が正確、最新かつ完全なものとしなければなりません。
          </p>
          <p>
            ご利用者は、各自の情報に変更があった場合には、当社が効果的にご利用者に連絡を取り、雇用者候補の正確な最新情報を提供することができるよう、速やかに各自の履歴書に必要な変更を加えるか、またはマイページ機能を利用して各自の個人情報を更新することにより、情報をアップデートし、当社に知らせる必要があります。
          </p>
          <p>
            ご利用者は、マイページを利用するために登録するときに、パスワードおよびログイン名を設定する必要があります。不正行為を防止するため、このパスワードは秘密とし、誰にも開示したり教えたりしてはなりません。誰か他の人が各自のパスワードを知っていることが分かったまたは疑われる場合には、ただちに当社にご連絡いただく必要があります。なお当社は、不正使用に対する責任を一切負いません。
          </p>
          <p>
            ご利用者は、本サイトの利用により、各自のマイページアカウントに最新の事実に即した正確かつ完全な情報を保存し、いずれの第三者の知的財産権にも反せずこれらを侵害しない資料をアップロードすることなど、一切の事由につき単独で責任を負います。
          </p>
          <p>
            当社ウェブサイトのセキュリティ違反または誤用のおそれがあるとペイジグループが考える根拠がある場合には、当社はご利用者の方々に各自のパスワードを変更するよう求め、または各自のマイページアカウントの利用を停止することがあります。
          </p>
          <p>
            当社は、相当な裁量に従って、またはご利用者が本規約もしくはプライバシーポリシーに基づく義務のいずれかに違反した場合には、ただちにご利用者のアカウントを停止しまたは削除することがあります。
          </p>
          <p>
            ご利用者は、いつでもマイページにログインし、「アカウント削除」リンクを選択して、マイページアカウントおよび当該アカウント内の当該アカウント設定に応じた求人アラートを削除することができます。
          </p>
          <p>
            ご利用者のマイページアカウントおよび本ウェブサイトを利用する権利を停止しまたは削除しても、いずれの当事者が法律上有する権利または負う責任にも何ら影響は及ばないものとします。
          </p>
          <p>
            ご提供いただいた情報は、世界中のペイジグループで閲覧可能となります。
          </p>
          <p>
            <strong>集計情報</strong>
          </p>
          <p>
            当社は、本サイトの全訪問者に関する情報および統計データをひとまとめにして集めることができます。これにはご利用者から提供される情報で、当社のご利用者を把握し、より適切な求人プロセスを考案するのに役立つものを含みます。当社は個人の氏名または個人特定情報を開示しません。全てのデータは必ず集計形式とします。当社はこの情報が、当社のご利用者に最も有益な事項および当社のオンラインサービスを継続的に改善する方法を特定するのに役立つと考えております。当社は、そうした目的に資する選定した第三者に、この種の集計データを開示することがあります。なお当社では個人情報は、当社のプライバシーポリシーに従って処理します。
          </p>
          <p>
            <strong>知的財産</strong>
            <br />
            本ウェブサイト上に掲載され利用可能な商標、商号、ロゴ、情報および資料はすべて、マイケル･ペイジ･インターナショナル･ジャパン株式会社およびそのライセンサーに帰属する財産です。
          </p>
          <p>無断複製を禁じます。</p>
          <p>
            ご利用者は、非営利目的または個人利用に限って、本ウェブサイト上の情報および資料を閲覧、使用および印刷することができます。ただし、あらゆる著作権表示および所有権表示を損なってはなりません。
          </p>
          <p>
            その他にご利用者は、本ウェブサイトのコンテンツ、フォーマットまたはデザインについて、これを複製、翻案、保管、送信、印刷、表示、営利目的で利用し、発行しまたはこれの一部に基づいた派生物を作成してはなりません。
          </p>
          <p>
            ご利用者は、コンテンツを複製しまたはその他にどのような形であれ利用しようとする場合には、必要に応じて当該利用について当社から承認を得なければなりません。
          </p>
          <p>
            <strong>認められる利用に関する規則</strong>
            <br />
            ご利用者は、本ウェブサイトを通じて提供されるサービスを、もっぱら所定の目的に限って利用しなければなりません。ご利用者は、本ウェブサイトにより提供されるサービスを、下記の目的で利用してはなりません。
            <br />
            (a)&nbsp;違法な、差別的な、嫌がらせの、中傷的な、名誉を毀損する、侮辱的な、脅迫的な、有害な、無礼な、猥褻な、不法な、その他礼節に反する資料を送信、アップロード、表示もしくは配布し、もしくはその他の形で利用させること、またはその他に法律に違反すること。
          </p>
          <p>
            (b)&nbsp;刑事犯罪にあたりうる行為、民事責任の成立につながりうる行為、またはその他適用ある法律、規則もしくは服務規定に反するおそれのある行為を促す資料を表示、アップロードまたは送信すること。
          </p>
          <p>
            (c)&nbsp;他の人のプライバシーまたは本ウェブサイトの利用もしくは享受を妨げること。
          </p>
          <p>
            (d)&nbsp;自らが他の人もしくは他の法主体の代表（ペイジグループの代表を含むが、これに限らない。）であると偽りもしくはその他の形で不実表示を行い、または自らがいずれかの人、法主体もしくは組織の関係者であると偽りもしくはその他の形で不実表示を行うこと。
          </p>
          <p>
            誤ったまたは虚偽の情報を提供することにより（情報の遺漏を含む）、ペイジグループ、その代表者および提供された情報に依拠する第三者を惑わせまたは欺くこと。
          </p>
          <p>
            (e)&nbsp;本ウェブサイトにより提供されるサービスを通じて送信される資料の出所を偽ること（メッセージ／パケット・ヘッダーの偽造またはその他通常の識別情報の操作によるものかを問わない）。
          </p>
          <p>
            (f)&nbsp;いずれかの人の知的財産権または工業所有権（いずれかの当事者の著作権、商標、特許、営業秘密またはその他所有権）を侵害する資料を送信、アップロードし、またはその他の形で入手させること。
          </p>
          <p>
            (g)&nbsp;スパム、ジャンクメール、ねずみ講の宣伝、チェーンレター、ウィルス警告（警告の信憑性が未確認のもの）またはその他の形式の無断広告もしくは販促資料を含みまたはこれらに関する資料を送信、アップロード、表示もしくは配布し、またはその他の形で利用させること。
          </p>
          <p>
            (h)&nbsp;ウィルス、トロイの木馬、またはその他ハードウェアまたはソフトウェアの性能を損ない、破壊しもしくは支障を生じさせる目的を持った物を含んだ資料を送信、アップロードし、またはその他の形で入手させること。
          </p>
          <p>
            (i)&nbsp;サービスを提供するサーバーもしくは関連ネットワーク上のサーバーに不正にアクセスしもしくはかかるサーバーの性能に支障を生じさせ、またはその他の形でかかるサーバーの使用に関する規則もしくは手続に違反すること。
          </p>
          <p>
            (j)&nbsp;サービスの他のユーザーに関するデータを、集計またはその他の形でかを問わず、収集すること。
          </p>
          <p>
            © ペイジグループ2013年
            <br />
            2013年3月
          </p>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Terms;
